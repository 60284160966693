import React from 'react';
import { graphql } from 'gatsby';
import { Col, Row, Nav, NavItem, NavLink, Button } from 'reactstrap';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-intl';

import { Layout, SEO, Image, PostCard } from '../../components';
import coverPic from '../../assets/images/community-header.png';
import messages from '../../messages/community';
import filterByLanguage from '../../utils/filterByLangage';
import countries from '../../constants/countries';

const ProfilePage = ({
  data: { member, articles: memberArticles },
  pageContext: {
    intl: { language: currentLanguage }
  }
}) => {
  const intl = useIntl();
  const articles = memberArticles?.group;
  const memberCountry =
    countries.find(
      country =>
        country[member.memberFields.country.toString().toUpperCase()] !==
        undefined
    )[member.memberFields.country] || '';
  return (
    <Layout fluid background={false} pure>
      <SEO title={member.name} />
      <Row
        className="mx-0 p-O community-header mb-5"
        style={{ backgroundImage: `url(${coverPic})` }}
      >
        <div className="d-flex align-items-center w-100 justify-content-center">
          <div className="text-center community-header-title">
            {intl.formatMessage(messages.headerQuote)}
          </div>
        </div>
      </Row>
      <div className="px-5 container">
        <Row>
          <Col>
            <div className="activities-tabs-and-search mb-5">
              <Nav tabs className="activities-tabs">
                <NavItem>
                  <NavLink active className="community-nav-link">
                    {intl.formatMessage(messages.community)}
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </Col>
        </Row>
        <Row>
          <Col md="7">
            <div className="profile-member-name">{member.name}</div>
            {member.description && (
              <>
                <div className="profile-member-bio-title mt-3">
                  {intl.formatMessage(messages.bio)}
                </div>
                <div className="profile-member-bio">{member.description}</div>
              </>
            )}
            {member.memberFields?.quote && (
              <>
                <div className="profile-member-quote-title mt-3">
                  {intl.formatMessage(messages.quote)}
                </div>
                <div className="profile-member-quote">
                  {member.memberFields.quote}
                </div>
              </>
            )}

            {member?.memberFields?.country && (
              <Button className="profile-member-country mt-3">
                {memberCountry}
              </Button>
            )}
          </Col>
          <Col md="5">
            <div className="d-flex justify-content-center">
              <div className="profile-member-pic-container">
                <Image
                  src={member.avatar?.url}
                  alt={member.name}
                  width="100%"
                  className="profile-community-country-member-card"
                />
                <div className="profile-community-country-member-card-name-box">
                  {member.name}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        {articles?.length > 0 && (
          <>
            <Row>
              <div className="profile-member-articles-title mt-5 mb-3">
                {intl.formatMessage(messages.articles)}
              </div>
            </Row>
            <Row lg="3" md="2" xs="1">
              {filterByLanguage(articles, currentLanguage).map(
                ({ node: article }) => (
                  <PostCard post={article} key={article.id} />
                )
              )}
            </Row>
          </>
        )}
      </div>
    </Layout>
  );
};
ProfilePage.propTypes = {
  member: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    memberFields: PropTypes.shape({
      country: PropTypes.string,
      memberSince: PropTypes.string
    }),
    avatar: PropTypes.shape({
      url: PropTypes.string
    })
  })
};
ProfilePage.defaultProps = {
  member: {}
};
export default ProfilePage;
export const query = graphql`
  query($id: String!) {
    member: wpUser(id: { eq: $id }) {
      id
      slug
      name
      description
      memberFields {
        country
        memberSince
      }
      avatar {
        url
      }
    }
    articles: allWpPost(
      filter: { author: { node: { id: { eq: $id } } } }
      sort: { fields: [date, language___slug], order: DESC }
    ) {
      group(field: language___slug) {
        edges {
          node {
            id
            slug
            title
            content
            dateAgoFr: date(fromNow: true, locale: "fr")
            dateAgoEn: date(fromNow: true, locale: "en")
            date(formatString: "D/MM/YYYY")
            excerpt
            tags {
              nodes {
                id
                slug
                name
              }
            }
            categories {
              nodes {
                id
                slug
                name
              }
            }
            author {
              node {
                id
                slug
                name
                avatar {
                  url
                }
              }
            }
            translations {
              slug
            }
            featuredImage {
              node {
                id
                sourceUrl
                localFile {
                  publicURL
                }
              }
            }
            language {
              locale
              slug
            }
          }
        }
      }
    }
  }
`;
